import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import vs from '../images/versus.png'
import rupeeIcon from '../images/global-rupeeIcon.png'
import ios from '../images/ios.png'
import android from '../images/android.jpg'
import axios from "axios";
const RoomCodePopular = () => {
  const [gamebat, setGamebat] = useState(
    {
        game_url: "Ludo-Popular",
        amount: '',
        label: ""
    }
)
const [gameid, setgameid] = useState({

    "game_id": "2"
})

const [users, setUsers] = useState([]);
const [roomcode, setRoomCode] = useState([]);
const [battleId, setBattleId] = useState('null');

  useEffect(
      () => {
          loaduser();
      }, []
  );

  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    const config = {
      method: "post",
      url: "https://api.vplay.bet/api/users/gameopen",
      headers: {
        "x-access-token": token
      },
      data: gameid
    };

    try {
      const response = await axios(config);
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes.data);

      if (objectRes.data.length > 0) {
        setRoomCode(objectRes.data[0].LOBBY_ID);
        setBattleId(objectRes.data[0].id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [fileData, setFileData] = useState('null');
  const [selectedOption, setSelectedOption] = useState('');
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('false');
  const player_id = localStorage.getItem("userid");
  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setShowFileUpload(value === "win");
    setShowMessage(value === "lost");
    setMessage(value === "cancel" ? "" : "false");
    setFileData(null); 
  };
  
  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = "http://vplay.bet/Admin/public/battle-result-store-react";
    const formData = new FormData();
    formData.append('image', fileData);
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('player_id', player_id);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      });

      // if (response.ok) {
      //   console.log("API request succeeded!");
        
      // } else {
      //   console.error("API request failed.");
       
      // }
    } catch (error) {
      console.error("An error occurred while making the API request:", error);
     
    }
  };


  const handleSubmit1 = async (e) => {
    e.preventDefault();
  
    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = "http://vplay.bet/Admin/public/battle-result-store-react";
    const formData = new FormData();
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('player_id', player_id);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      });

     
    } catch (error) {
      console.error("An error occurred while making the API request:", error);
     
    }
  };

  const handleCopyClick = () => {
    const textField = document.createElement('textarea');
    textField.innerText = roomcode;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
    alert('Room code copied to clipboard!');
  };


return (
    <>
    <div className="container"></div>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">

          <div className="text-center">
            <div className="roomcode">
                  <div className="d-flex justify-content-center width_300">
                      <div className="user1">
                          <img className="img-fluid" src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"/>
                      </div>
                      <div className="vs">
                          <img src={vs} className="img-fluid"/>
                      </div>
                      <div className="user2">
                          <img className="img-fluid" src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"/>
                      </div>
                  </div>
                  <div className="amountplay">
                      <p className="playing">Playing for <img src={rupeeIcon} className="img-fluid money_width"/> 100</p>
                  </div>

              </div>
              <hr></hr>

          <div className="codebg">
            <h5>Room Code</h5>
            <h6>{roomcode}</h6>
            <button className="btn btn-green btn_copy" onClick={handleCopyClick}>
          Copy Here
        </button>
          </div>
      
      <div className="luodking">
        <p>Play ludo game in Ludo King App</p>
        <div className="d-flex jusitfy-content-center anroid_icon">
          <div className="iosandroidimage">
          <img src={android} className="img-fluid android" />
          </div>
          <div className="iosandroidimage">
          <img src={ios} className="img-fluid android"/>
          </div>
          </div>
        </div>
         <button className="bg-green playButton position-static mt-2" onClick={handleCopyClick}>Copy Code</button>
    </div>
           
          </div>
        </div>
      </div>
      <div className="width_status">
      <div class="rules"><span class="cxy mb-1"><u>Game Rules</u></span>
				<ol class="list-group list-group-numbered">
					<li class="list-group-item">Record every game while playing.</li>
					<li class="list-group-item">For cancellation of game, video proof is necessary.</li>
					<li class="list-group-item">50 Penality will be charged for updating wrong result.</li>
					<li class="list-group-item">25 Penality will be charged for not updating result.</li>
				</ol>
			</div>
      </div>

      <div className="width_status">
      <div className="match-status-border row">
				<div className="matchstaus" style={{fontSize:20, fontWeight:800}}>Match Status</div>
			
   <form className="form-room text-center">
        <label className="radio-inline">
          <input
            type="radio"
            value="win"
            checked={selectedOption === "win"}
            onChange={handleOptionChange}
          />
          I won
        </label>
        <label className="radio-inline">
          <input
            type="radio"
            value="lost"
            checked={selectedOption === "lost"}
            onChange={handleOptionChange}
          />
          I lose
        </label>
        <label className="radio-inline">
          <input
            type="radio"
            value="cancel"
            checked={selectedOption === "cancel"}
            onChange={handleOptionChange}
          />
          Cancel
        </label>

        {/* File Upload */}
        {showFileUpload && (
          <>
          <div className="file_upload">
            <label>
              <input type="file" onChange={(e) => setFileData(e.target.files[0])} />
            </label>
          </div>
          <br/><br/>
          <div className="btn_details">
          <button type="submit" className="btn btn-green" onClick={handleSubmit}>
            Submit Details
          </button>
          </div>
          </>

        )}

        {/* Show message */}
        {showMessage && (
          <>
          <div className="text-center">
            <p>Better luck next time!</p>
          </div>
          <div className="btn_details">
          <button type="submit" className="btn btn-green" onClick={handleSubmit1}>
            Submit Details
          </button>
          </div>
          </>

        )}

        {/* {message && (
          <div className="text-center">
            <label>
              Textarea:
              <textarea value={message} onChange={handleTextareaChange} />
            </label>
          </div>
        )} */}

        <br />
        {/* <div className="btn_details">
          <button type="submit" className="btn btn-green">
            Submit Details
          </button>
        </div> */}
      </form>
      </div>
        </div>
    </>
  );
};
export default RoomCodePopular;