import React,{useState, useEffect} from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";
// import { IconName } from "react-icons/io";
import logo from '../images/vplaylogo.png';
import wallet from '../images/sidebar-wallet.png';
import support from '../images/sidebar-support.png';
import referEarn from '../images/sidebar-referEarn.png';
import notifications from '../images/sidebar-notifications.png';
import gamepad from '../images/gamepad.png';
import frndsend from '../images/frndsend.png';
import gamesHistory from '../images/sidebar-gamesHistory.png';
import history from '../images/order-history.png';
import axios from "axios";

// const Notification = ()=>{

//   const [users, setUsers] = useState([]);


//   useEffect(
//       () => {
//           loaduser();
//           // alert('game')

//       }, []
//   );

//   const loaduser = async () => {

//       var data = '';

//       var config = {
//           method: 'post',
//           url: 'http://103.129.97.243:8080/api/users/notification-count', 
//           headers: {
//               'x-access-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs'
//           },
//           data: data,
//           // setdata: () => {
//           //     sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs')
//           // }
//       };

//       axios(config)
//           .then(function (response) {
//               const res = JSON.stringify(response.data);
//               const objectRes = JSON.parse(res);
//               console.log(objectRes.data);
//               // alert(objectRes.data)
//               setUsers(objectRes.data);
//               console.log(users[2].id);
//               // config.setdata();
//           })
//           .catch(function (error) {
//               console.log(error);
//           });
//   }

// }



export const SidebarData = [

  {
    title: "profile",
    path: "/profile",
    // icon: <IoIcons.IoIosPaper />,
    icon: <img src={logo} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Game",
    path: "/dashbord",
    // icon: <IoIcons.IoIosPaper />,
    icon: <img src={gamepad} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Add Money/Withdrow",
    path: "/wallet",
    icon: <img src={wallet} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Send-Money To Friend",
    path: "/send-money",
    icon: <img src={frndsend} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Game-History",
    path: "/game-history",
    icon: <img src={gamesHistory} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Top-10-Players",
    path: "/top-10-players",
    icon: <img src={gamesHistory} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Transaction History",
    path: "/transaction-history",
    icon: <img src={history} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Refer-Earn",
    path: "/refer-earn",
    icon: <img src={referEarn} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    
    title: "Notification(0)",
    path: "/notification",
    icon: <img src={notifications} alt="" />,
    cName: "nav-text menu_list",
  },
  {
    title: "Support",
    path: "/support",
    icon: <img src={support} alt="" />,
    cName: "nav-text menu_list",
  },

];
