
import React, { useEffect, useState } from "react";
import battleIcon from '../images/global-purple-battleIcon.png'
import edit from '../images/icon-edit.jpg'
import imgedit from '../images/imageicn.png'
import profile2 from '../images/sidebar-wallet.png'
import myprofile from '../images/myprofile.jpg'
import kyc from '../images/kyc-icon-new.png'
// import right1 from '../images/right.png'
import cash from '../images/global-cash-won-green-circular.png'
import rup from '../images/global-rupeeIcon.png'
import right from '../images/right.png'
import { Link } from "react-router-dom";
import axios from "axios";
// import { Email } from "@mui/icons-material";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import mailpng from "../images/mail.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import { hover } from "@testing-library/user-event/dist/hover";

const Profile = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const [users, setUsers] = useState([]);
  const [displaycash_won, setcash_won] = useState([]);
  const [displayed_battle, setplayed_battle] = useState([]);
  console.log(users);
  const [editflag, setEditflag] = useState(false);
  const setToken = sessionStorage.getItem('token')
  const navigate = useNavigate();
  const [fileData, setFileData] = useState('null');

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  useEffect(
    () => {
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
      headers: {
        'x-access-token': token
    },
    data: {ipaddress:ipaddress}
    };
  
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if(totlaipp != ipaddress){
          localStorage.setItem('token_store_vplay', ' ');
          localStorage.setItem('userid',' ');
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
      if(!token){
        window.location.href = '/login';
        // exist();
      }
  }
  useEffect(
    () => {
      loaduser();

    
        var readURL = function(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
    
                reader.onload = function (e) {
                    $('.profile-pic').attr('src', e.target.result);
                }
        
                reader.readAsDataURL(input.files[0]);
                setFileData(input.files[0]);
                profileupdate(input.files[0]);
            }
        }
        
    
        $(".file-upload").on('change', function(){
            readURL(this);
        });
        
        $(".upload-button").on('click', function() {
           $(".file-upload").click();
        });
   
    }, [true]
  );
  const profileupdate = async (e) => {
    // e.preventDefault();
    // alert(fileData);
      const apiUrl = "https://vplay.bet/Admin/public/profile-update-react";
     
      const formData = new FormData();
      formData.append('fileData', e);
      formData.append('userid', userId);
  
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          mode: "no-cors",
          body: formData,
        }).then((response) => {
          // if (response.data.message == true) {
            // const MySwal = withReactContent(Swal)
            // MySwal.fire({
            //   title: <strong>Profile Update Successfully!</strong>,
            //   icon: 'success',
            //   showCloseButton: true,
            //   showCancelButton: false,
            //   showConfirmButton: false,
            //   timer: 5000,
    
            // }).then((result) => {
            //   window.location.href = "/profile";
            // })
        });
  
       
      } catch (error) {
        console.error("An error occurred while making the API request:", error);
  
      
    }
   

  };

  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/get-profile',
      headers: {
        'x-access-token': token
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.log(objectRes.data[0]);
        const userId = objectRes.data[0].id;
        localStorage.setItem("user_id", userId);
        setUsers(objectRes.data[0]);
        setcash_won(objectRes.cash_won[0]);
        setplayed_battle(objectRes.played_battle[0]);
        sessionStorage.setUsers("req_id", users.headers);
        console.warn(objectRes);

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  // ======UserNameUpdate=====
  const [userprofile, setUserprofile] = useState(
    {
      username: ""
    }
  )

  const onsavechange = (e) => {

    setUserprofile({ ...userprofile, "username": e.target.value })
  }

  const updateUsername = async () => {
    try {
      // Reset validation errors
      setValidationErrors({});

      const errors = {};

      if (!userprofile) {
        errors.userprofile = "userprofile is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      if (
        userprofile === null

      ) {
        return;
      }
      const token = localStorage.getItem("token_store_vplay");
      var config = {
        method: 'post',
        url: 'https://api.vplay.bet/api/users/username-update',

        headers: {
          'x-access-token': token
        },
        data: userprofile
      };
      axios(config)
        .then(function (response) {
          if (response.data.message == 'UserName Update Successfully') {
            const MySwal = withReactContent(Swal)
            MySwal.fire({
              title: <strong>User Name Update Successfully!</strong>,
              icon: 'success',
              timer: 1500
            })
          } else {
            const MySwal = withReactContent(Swal)
            MySwal.fire({
              title: response.data.message,
              icon: 'error'
            })
          }
          loaduser();
          setEditflag(false);
        })
    } catch (error) {
      console.error("User Name Update:", error);
    }
  }

  // ======Email Update======

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [email, setEmail] = useState();
  const [massage, setMassage] = useState()


  const [emailprofile, setEmailprofile] = useState(
    {
      "email": ""
    }
  )
  // const [newName, setnewName] = useState()
  const onsavechangeemail = (e) => {
    // setnewName(e.target.value);
    setEmailprofile({ ...emailprofile, "email": e.target.value })
  }
  const updateEmail = async () => {
    const token = localStorage.getItem("token_store_vplay");
    console.log(emailprofile);
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/emailid-update',
      headers: {
        'x-access-token': token
      },
      data: emailprofile
    };
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.log(objectRes);
        loaduser();
        setEditflag(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  // =====Logout=====


  const logout = () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/logout',
      headers: {
        'x-access-token': token
      },
      data: userprofile
    };
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.log(objectRes.status);
        // loaduser();
        // setEditflag(false);
        if (objectRes.status == 1) {
          localStorage.clear();
          // navigate('/login')
          window.location.href = 'login';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // alert('aaa');
    // localStorage.clear();
  }


  const [referralId, setReferralId] = useState('');
  const userId = localStorage.getItem("userid");
  const [referralIdSet, setReferralIdSet] = useState(false); 

  const handleReferralChange = (event) => {
    if (!referralIdSet) {
      setReferralId(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const apiUrl = 'https://vplay.bet/Admin/public/saveRefferByReact';

    const formData = new FormData();
    formData.append('userid', userId);
    formData.append('refferalID', referralId);

    axios.post(apiUrl, formData)
    const myTimeout = setTimeout(myGreeting, 3000);

function myGreeting() {

  window.location.reload(); 
  // document.getElementById("demo").innerHTML = "Happy Birthday!"
}
      // .then(response => {
      //   console.warn(response.data+'rfggfgfgfgfgfgfgfg');
      // })
      // .catch(error => {
      //   console.error(error);
      // });
  };

  return (
    <>

      <div className="leftContainer">
        <div className="main-area " style={{ paddingTop: '60px' }}>
          <div className="p-3" style={{ background: 'rgb(250, 250, 250)' }}>
            <div id="sign_up_success" />
            <div className="center-xy py-2">
              <div id="profile_updated_msg" />

              <picture className="position-relative">
             
                <img className="border-50 profile-pic upload-button" height="80px" width="80px" src=
                  // users
                  {users.profile_image == null? (
                    myprofile
                  ) : (
                    "https://vplay.bet/Admin/public/frontend/profile/"+users.profile_image
                  )}
                 
                  />
             
              
     <div className="p-image">
       <i className="fa fa-camera upload-button"></i>
      
     </div>
                <input type="file" className="file-upload" accept="image/*"/>
               
                <div className="image-upload" style={{ position: 'absolute', top: '15%', right: '45%', color: 'transparent' }}>
                  <label htmlFor="file_input">
                    <i className="fa fa-upload" />
                  </label>
                </div>
              </picture>

              <span className="battle-input-header mr-1">+91{users.mobile} </span>

              <div className="text-bold my-3 vplay_id">{users.vplay_id}
                <img className="ml-2" width="20px" src={edit} alt="" onClick={() => { setEditflag(true) }} />
              </div>
              <div className="" style={{ padding: '10px', display: 'block' }}>
                <div className="d-flex align-items-center position-relative" style={{ height: '' }}>

                  <div className="">

                    {editflag ?
                      <>
                        <div className="refferForm d-flex">
                          <div className="MuiFormControl-root MuiTextField-root" style={{ verticalAlign: 'bottom' }}>
                            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                              <input aria-invalid="false" type="text" onChange={onsavechange} value={userprofile.username} name='username' placeholder="Enter Username" className=" MuiInput-input refferalID form-control " />
                              {validationErrors.username && <p>{validationErrors.username}</p>}


                            </div>
                          </div>
                          {/* <button type="submit" onClick={()=>{updateUsername()}}>Update</button> */}
                          <button className="btn btn-primary" onClick={() => { updateUsername() }} style={{ position: 'absolute', right: '-35px', }} width="30px" >Ok</button>
                          {/* <img className="ml-2 update" onClick={() => { updateUsername() }} style={{ position: 'absolute', right: '-35px', }} width="30px" src={right1} alt="" /> */}
                        </div>
                        <div className="refferIdshow">
                          <div className="MuiFormControl-root MuiTextField-root" style={{ verticalAlign: 'bottom' }}>
                            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                            </div>
                          </div>
                        </div>
                      </>
                      : null}
                  </div>


                </div>
              </div>

              <Link className="d-flex align-items-center profile-wallet w-100" to="/wallet">
                <picture className="ml-4"><img width="32px" src={profile2} alt="" /></picture>
                <div className="ml-5 mytext text-muted ">My Wallet</div>
              </Link>
            </div>
          </div>
        </div>

        <div className="divider-x" />
        <div className="p-3">
          <div className="text-bold">Complete Profile</div>
          <Slider {...settings} className=" container">
            
            <a className=" container d-flex align-items-center profile-wallet bg-light  my-4 py-3" tabIndex={-1}>
              <picture className="ml-4">
                <img width="32px" src={mailpng} alt="" />
              </picture>
              {users.email == null ?
              <div className="ml-5 mytext text-muted " onClick={handleShow} >
              EMAIL UPDATE
            </div>
               :
               <div className="ml-5 mytext text-muted ">
               EMAIL UPDATED ✅
             </div>
                }
             
            </a>

            <div>
            {users.verify_status === 'kyc_pending' ?
              <Link to="/kyc" className="d-flex align-items-center profile-wallet bg-light  my-4 py-3" href="#" tabIndex={0}>
                <picture className="ml-4">
                  <img width="32px" src={kyc} alt="" />
                </picture>
                <div className="ml-5 mytext text-muted ">KYC Complete
                  {/* <img src={right} className="d-inline" style={{ width: '25px' }} /> */}
                </div>
              </Link>
              :
              <Link  className="d-flex align-items-center profile-wallet bg-light  my-4 py-3" href="#" tabIndex={0}>
              <picture className="ml-4">
                <img width="32px"  alt="" />
              </picture>
              <div className="ml-5 mytext text-muted ">KYC Completed
                <img src={right} className="d-inline" style={{ width: '25px' }} />
              </div>
            </Link>
            }
            </div>
          </Slider>
        </div>


        {/* <div className="divider-x" />
        <div className="p-3">
          <div className="text-bold">Complete Profile</div>
          <div className="d-flex ">
            <div style={{ overflowX: 'hidden', cursor: "pointer" }} >
              <div className="react-swipeable-view-container slick-initialized slick-slider" style={{ flexDirection: 'row', transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s', direction: 'ltr', display: 'flex', willChange: 'transform', transform: 'translate(0%, 0px)' }}>
                <button type="button" className="slick-prev pull-left slick-arrow" style={{ border: '0px solid white', backgroundColor: 'white', fontSize: '35px', color: 'rgb(150, 150, 150)', display: 'block' }}><i className="fa fa-angle-left" aria-hidden="true" /></button>
                <div className="slick-list draggable">
                  <div className="slick-track" style={{ opacity: 1, width: '1995px', }}>
                    <div aria-hidden="true" data-swipeable="true" style={{ width: '399px', flexShrink: 0, overflow: 'auto' }} className="slick-slide slick-cloned" data-slick-index={-1} tabIndex={-1}>
                      <a className="d-flex align-items-center profile-wallet bg-light mx-1 my-4 py-3" tabIndex={-1}>
                        <picture className="ml-4">
                          <img width="32px" src={mailpng} alt="" />
                        </picture>
                        <div className="ml-5 mytext text-muted " onClick={handleShow} >
                          EMAIL UPDATED ✅
                        </div>
                      </a>
                    </div>
                    <div aria-hidden="false" data-swipeable="true" style={{ width: '399px', flexShrink: 0, overflow: 'auto' }} className="slick-slide slick-current slick-active" data-slick-index={0} tabIndex={0}>
                      <Link to="/kyc" className="d-flex align-items-center profile-wallet bg-light mx-1 my-4 py-3" href="#" tabIndex={0}>
                        <picture className="ml-4"><img width="32px" src={kyc} alt="" />
                        </picture>
                        <div className="ml-5 mytext text-muted ">KYC Completed
                          <img src={right} className="d-inline" style={{ width: '25px' }} />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <button type="button" className="slick-next pull-right slick-arrow" style={{ border: '0px solid white', backgroundColor: 'white', fontSize: '35px', color: 'rgb(150, 150, 150)', display: 'block' }}><i className="fa fa-angle-right" aria-hidden="true" /></button>
              </div>
            </div>
          </div>
        </div> */}

        <form onClick={updateEmail}>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title> Update Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"

                    value={email}
                    name="email"
                    onChange={onsavechangeemail}
                    placeholder="name@gmail.com"
                    autoFocus
                  />
                </Form.Group>

              </Form>
            </Modal.Body>
            <p>{massage}</p>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Update
              </Button>

            </Modal.Footer>
          </Modal>
        </form>
        <div>
          
          <div className="divider-x" />
          <div className="px-3 py-1 user_refer_code">
            <div className="d-flex align-items-center  justify-content-between" style={{ height: '84px', width: '220' }}>
              {/* <picture><img height="32px" width="32px" src={right} alt="" />
              </picture> */}
              <i class="fa fa-user-plus" aria-hidden="true"></i>
                { users.reffered_by == null || users.reffered_by == '' ?
              <div className="pl-4">
                <div className="text-uppercase moneyBox-header" style={{ fontSize: '0.8em' }}>Use Refer Code</div>
                <div className="refferForm">
                <form onSubmit={handleSubmit} className="d-flex">
                  <div className="MuiFormControl-root MuiTextField-root" style={{ verticalAlign: 'bottom' }}>
                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                      <input
                        aria-invalid="false"
                        type="text"
                        className="forn-control border-top-left-right MuiInput-input refferalID"
                        style={{ outline: "none" }}
                        value={referralId}
                        onChange={handleReferralChange}
                        disabled={referralIdSet} 
                      />
                    </div>
                  </div>
                  <span>
                    <button type="submit" style={{background:"transparent"}}><i className="fa fa-check" aria-hidden="true" style={{ position: "relative", left: "7px", top: "7px" }}></i></button>
                  </span>
                </form>
            </div>
              </div>
              :
              <div className="refer_code">
              <div className="text-uppercase moneyBox-header" style={{ fontSize: '0.8em' }}>Used Refer Code</div>
                          <p className="refferalID">{users.reffered_by}</p>
                          </div>
              }
              {/* <i class="fa fa-check" aria-hidden="true" style={{ position: "relative", left: "7px", top: "7px" }}></i> */}
              <div className="refferIdshow">
                <div className="MuiFormControl-root MuiTextField-root" style={{ verticalAlign: 'bottom' }}>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="px-3 py-1">
            <div className="d-flex align-items-center position-relative" style={{ height: '84px' }}>
              <picture><img height="32px" width="32px" src={cash} alt="" />
              </picture>
              <div className="pl-4">
                <div className="text-uppercase moneyBox-header" style={{ fontSize: '0.8em' }}>Cash Won</div>
                <div>
                  <picture className="mr-1"><img height="auto" width="21px" src={rup} alt="" /></picture>
                  <span className="moneyBox-text" style={{ fontSize: '1em', bottom: '-1px' }}>₹{displaycash_won.won_amt}</span>
                </div><span className="thin-divider-x" />
              </div>
            </div>
          </div>
          <div className="px-3 py-1">
            <div className="d-flex align-items-center position-relative" style={{ height: '84px' }}>
              <picture><img height="32px" width="32px" src={battleIcon} alt="" /></picture>
              <div className="pl-4">
                <div className="text-uppercase moneyBox-header" style={{ fontSize: '0.8em' }}>Battle Played</div>
                <div><span className="moneyBox-text" style={{ fontSize: '1em', bottom: '-1px' }}>{displayed_battle.played_battle}</span></div>
              </div>
            </div>
          </div>
          <div className="px-3 py-1">
            <div className="d-flex align-items-center position-relative" style={{ height: '84px' }}>
              <picture><img height="32px" width="32px" src={cash} alt="" />
              </picture>
              <div className="pl-4">
                <div className="text-uppercase moneyBox-header" style={{ fontSize: '0.8em' }}>Reffer Earning</div>
                <div>
                  <picture className="mr-1"><img height="auto" width="21px" src={rup} alt="" /></picture>
                  <span className="moneyBox-text" style={{ fontSize: '1em', bottom: '-1px' }}>₹{users.redeem_amount}</span>
                </div><span className="thin-divider-x" />
              </div>
            </div>
          </div>

          <div className="divider-x" />
          <div className="p-3" onClick={logout}> <Link to="" className="center-xy text-muted text-uppercase py-4 font-weight-bolder">Log Out</Link></div>
        </div>
      </div>
    </>
  );
}
export default Profile;