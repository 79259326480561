import React,{useState,useEffect} from "react";
import $ from 'jquery';
import axios from "axios";

const Addfund = () => {
  const [price, setRupee] = useState();
  const [usserid, seetusserid] = useState();
  const [users, setUsers] = useState([]);
//   const btnnext = document.getElementById('btnnext');
//   const fontrupee = document.querySelector('.font_rupee');

  
//  function input(){
  
//   const textfield = document.getElementById('Textfield').value;
//   if (textfield.value == null || textfield.value == '' ){
//     btnnext.style.backgroundColor="red";
//   }
//   else{
//     btnnext.style.backgroundColor="blue";
//   }
//   fontrupee.onclick = function(){
//     btnnext.style.backgroundColor ="black";
//   }
// }
useEffect(() => {
  const userId = localStorage.getItem("userid");
  seetusserid(userId);
});
useEffect(
  () => {
    genralsttting();
  }, [true]
);

const genralsttting = async () => {
  const token = localStorage.getItem("token_store_vplay");
  var config = {
    method: 'post',
    url: 'https://api.vplay.bet/api/users/get-genral-setting',
    headers: {
      'x-access-token': token
    },
  };

  axios(config)
    .then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes.data[0]);

    })
    .catch(function (error) {
      console.log(error);
    });

}
useEffect(
  () => {
    multiple_auth_chk();
  }, []
);
const multiple_auth_chk = async () => {


  var data = '';
  const ipaddress = localStorage.getItem("ipaddress");
  const token = localStorage.getItem("token_store_vplay");
  var config = {
    method: 'post',
    url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
    headers: {
      'x-access-token': token
  },
  data: {ipaddress:ipaddress}
  };

  axios(config)
    .then(function (response) {
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      // console.warn(objectRes.data[0]['total']);
      var totlaipp = objectRes.data[0]['ip'];
      // alert(totlaipp);
      if(totlaipp != ipaddress){
        localStorage.setItem('token_store_vplay', ' ');
        localStorage.setItem('userid',' ');
        window.location.href = '/login';
      }
      console.warn(totlaipp);
      // setUsers(objectRes);
    })
    .catch(function (error) {
      console.log(error);
    });
    if(!token){
      window.location.href = '/login';
      // exist();
    }
}

  return (
    <>
      <div className="leftContainer">
        <div className="px-4 py-3"  >
          <div className="games-section" style={{ paddingTop: '60px' }}>
              {/* <p>ध्यान दें पैसा जोड़ते समय ओहके का इंतजार करे ! धन्यवाद</p>
              <p>Payment Add Karte Time Ok Hone Tak Ka Wait Karen ! Thanks</p> */}
            <div className="d-flex position-relative align-items-center">
              <div className="games-section-title">Choose amount to add</div>
            </div>
          </div>
          <form action="https://vplay.bet/Admin/public/payments/store" method="post">
            
            <input type="hidden" name="_token"  defaultValue="3cyKTfv8CH23pNxM0tr0A5U3VWkUs95HdAw95sJo" />			<div className="pb-3">
              <div className="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                <div className="MuiFormControl-root MuiTextField-root"><label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true">Enter Amount</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                    {/* <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                      <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary">₹</p>
                    </div> */}
                    <input type="number" value={price} required name="amount" id="Textfield" className="form-control border-bottom" placeholder="₹" style={{ border: 'none' }} min={users.min_deposit}/>
                    <input type="hidden" value={usserid} name="userid" id="Textfield" className="form-control border-bottom" />
                  </div>
                  <p className="MuiFormHelperText-root">Min: 10, Max: 20000</p>
                </div>
              </div>
              <div className="games-window">
                <div className="gameCard-container" id="amount1">
                  <button type="button" className="add-fund-box font_rupee"  onClick={() => setRupee("100")}>
                    {/* <button style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>100</div>
                    </button> */}
                  <span style={{ fontSize: '0.9em'}}>₹</span>100
                  </button>
                </div>
                <div className="gameCard-container" id="amount2">
                  {/* <div className="add-fund-box">
                    <div style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>250</div>
                    </div>
                  </div> */}
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setRupee("250")}>
                    {/* <button style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>100</div>
                    </button> */}
                  <span style={{ fontSize: '0.9em'}}>₹</span>250
                  </button>
                </div>
                <div className="gameCard-container" id="amount3">
                  {/* <div className="add-fund-box">
                    <div style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>500</div>
                    </div>
                  </div> */}
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setRupee("500")}>
                    {/* <button style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>100</div>
                    </button> */}
                  <span style={{ fontSize: '0.9em'}}>₹</span>500
                  </button>
                </div>
                <div className="gameCard-container" id="amount4">
                  {/* <div className="add-fund-box">
                    <div style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>1000</div>
                    </div>
                  </div> */}
                  <button type="button" className="add-fund-box font_rupee" onClick={() => setRupee("1000")}>
                    {/* <button style={{ display: 'flex', alignItems: 'baseline' }} onClick={changeone}>
                      <div className="collapseCard-title mr-1" style={{ fontSize: '0.9em' }}>₹</div>
                      <div className="collapseCard-title" style={{ fontSize: '1.5em' }}>100</div>
                    </button> */}
                  <span style={{ fontSize: '0.9em'}}>₹</span>1000
                  </button>
                </div>
              </div>
              {/* <div className="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                <div className="MuiFormControl-root MuiTextField-root"><label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true">Enter UPI</label>
                  <div className="MuiInputBase-root MuiInput-root MuiInput-underline jss1 MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                      <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary" />
                    </div>
                    <input name="upi" aria-invalid="false" id="Textfield" type="text" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart" defaultValue style={{ fontWeight: 900 }} />
                    <input type="text" name="upi" id="amount" className="form-control border-bottom" placeholder="₹" style={{ border: 'none' }} />

                  </div>
                </div>
              </div> */}
              {/* <br /> */}
              {/* <h4>Payment Method</h4>
              <div className="form-check">
                <input type="radio" className="form-check-input" id="radio1" name="paymentGetwayId" defaultValue={1} defaultChecked />
                <label className="form-check-label" htmlFor="radio1">cash free</label>
              </div> */}
            </div>
            <div className="refer-footer">
              {/*<button disabled class="refer-button cxy w-100 bg-secondary disabledButton">Next</button>*/}
              <button type="submit" className="disabledButton btn btn-primary btn-lg" id="btnnext" style={{ width: '100%' }} >NEXT</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Addfund;  