
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import rup from '../images/global-rupeeIcon.png'
const Withdrawupi = () => {
  // const [upi, setUpi] = useState("");
  // const [amount, setAmount] = useState("");

  const [validationErrors, setValidationErrors] = useState({});
  // Api  Winning Cash Amount
  const [users, setUsers] = useState([]);
  const [GenralSetting, setGenralSetting] = useState([]);
  useEffect(
    () => {
      loaduser();
      genralsttting();
    }, []
  );
  const genralsttting = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/get-genral-setting',
      headers: {
        'x-access-token': token
      },
    };
  
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setGenralSetting(objectRes.data[0]);
  
      })
      .catch(function (error) {
        console.log(error);
      });
  
  }
  const loaduser = async () => {


    var data = '';

    const token = localStorage.getItem("token_store_vplay");
    // var config = {
    //   method: 'post',
    //   url: 'http://103.152.79.195:8080/api/users/header-amount',
    //   headers: {
    //     'x-access-token': token
    //   },
    //   data: data,
    // };

    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/get-wining-cash',
      headers: {
        'x-access-token': token
      },
      data: data,
      setdata:()=>{
        sessionStorage.setItem(token)
      }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  // Api Withdraw Amount
  const [gamebat, setGamebat] = useState(
    {
      amount: '',
      upi: ""
    }
  )
  const { amount, upi } = gamebat;
  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  }
  const handleAddBankSubmit = async () => {
    // alert = ('fffff');
    const token = localStorage.getItem("token_store_vplay");
    try {
      // Reset validation errors
      setValidationErrors({});

      // Check if any of the required fields are empty
      const errors = {};

      if (!upi) {
        errors.upi = "UPI is required";
      }
      if (!amount) {
        errors.amount = "Amount is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Check if any field has null or undefined value
      if (
        amount === null ||
        upi === null

      ) {
        return;
      }
      if(parseInt(GenralSetting.min_withdraw) > parseInt(amount)){
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: 'Please Withdraw Min '+GenralSetting.min_withdraw,
          icon: 'error'
        })
        return;
      }
      $('#redeemNow').attr('disabled', 'true');

      var config = {
        method: 'post',
        url: 'https://api.vplay.bet/api/users/withdraw-amount-upi-store',
        headers: {
          'x-access-token': token
        },
        data: gamebat
      };
      axios(config).then((response) => {
        $('#redeemNow').attr('disabled', 'false');
        console.warn(response);
        if (response.data.status == 1) {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Amount is Successfully!</strong>,
            icon: 'success',
            timer: 1500
          })
          .then((result) => {
            window.location.reload();
          })
        } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            icon: 'error'
          })
        }
      }).catch(function (error) {
        console.log(error);
      });

    }

    catch (error) {
      console.error("Error adding bank:", error);
      // Handle error (e.g., display error message)
    }
  };
  useEffect(
    () => {
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
      headers: {
        'x-access-token': token
    },
    data: {ipaddress:ipaddress}
    };
  
    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if(totlaipp != ipaddress){
          localStorage.setItem('token_store_vplay', ' ');
          localStorage.setItem('userid',' ');
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
      if(!token){
        window.location.href = '/login';
        // exist();
      }
  }
  return (
    <>
      <div className="leftContainer">
        <div>
          <div className="row" style={{ margin: '4px', backgroundColor: '#fff', height: '50px', marginTop: '60px', flexWrap: 'nowrap' }}>
            <div className="col-md-8 col-6 mt-3" style={{ fontSize: '0.85em', color: '#959595' }}>Winning Cash Amount</div>
            <div className="col-md-4 col-6 mt-3 text-right">
              <picture className="moneyIcon-container">
                <img src={rup} alt="" />
              </picture><strong>₹ {users.data}</strong>
            </div>
          </div>
          <div className="divider-x" />

          <div className="container">
            <div className="main-area" style={{ paddingTop: '0px' }}>
              <h5 className="my-3 mb-4" style={{ color: '#2c2c2c', fontSize: '1em', fontWeight: 700 }}>Withdraw Amount</h5>
              <div id="sign_up_success" />
              {/* <form method="post"> */}
              <input type="hidden" name="_token" defaultValue="jsoO9cwynnsTkYIjpQAuUf4RJTpZ3SFHCnrDOed7" /> <label className="w-20 mb-0" style={{ color: '#0000008a' }}>Amount</label>
              <input type="number" name="amount" id="amount" min={0} max={users.data} className="form-control border-bottom d-inline mb-2" style={{ border: 'none' }} value={amount} onChange={oninputchange} />
              <div className="text-danger" style={{ marginLeft: '20%' }} />
              {validationErrors.amount && <p>{validationErrors.amount}</p>}
              <br />
              <label className="w-20 mb-0" style={{ color: '#0000008a' }}>UPI</label>
              <input type="text" name="upi" id="upi" className="form-control d-inline border-bottom" style={{ border: 'none' }} value={upi} onChange={oninputchange} />
              <div className="text-danger" style={{ marginLeft: '20%' }} />
              {validationErrors.upi && <p>{validationErrors.upi}</p>}
              <br />
              <div className="row justify-content-center">
                <button type="primary" className="btn btn-primary my-2 w-100 mx-3 refer-button" id="redeemNow" onClick={handleAddBankSubmit}>Withdraw Now</button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Withdrawupi;