import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import upi from '../images/upi.webp'
import bank from '../images/bank.png'
import * as FaIcons from "react-icons/fa";
import axios from "axios";
const Withdrawamount = () => {
  const [users, setUsers] = useState([]);
  useEffect(
    () => {
      loaduser();


    }, [true]
  );
  const style = {
    height: "70px",
    mixBlendMode: "normal",
    opacity: "1",
    background: "#e0e0e0",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    textAlign: "center"
  }
  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/get-profile',
      headers: {
        'x-access-token': token
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes.data[0]);

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  useEffect(
    () => {
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
      headers: {
        'x-access-token': token
      },
      data: { ipaddress: ipaddress }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if (totlaipp != ipaddress) {
          localStorage.setItem('token_store_vplay', ' ');
          localStorage.setItem('userid', ' ');
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    if (!token) {
      window.location.href = '/login';
      // exist();
    }
  }
  return (
    <>
      <div className="leftContainer">
        <div className="container">
          <div className="px-4 py-3" >
            <div className="games-section margin-top-50">
              <div className="d-flex position-relative align-items-center">
                <div className="games-section-title">Choose withdrawal option</div>
              </div>
            </div>
            {users.verify_status != 'kyc_complete' ?
              <div className="card" style={style}>
              <a href="kyc" style={{"color":"black"}}>
              <span>Complete KYC to take Withdrawals</span>
              </a>
              </div>
              :
              <span></span>
            }
            {users.verify_status != 'kyc_complete' ?
              <div className="mt-3" style={{ opacity: 1 }}><Link to="/withdraw-amount">
                <div className="add-fund-box d-flex align-items-center mt-4" >
                  <div className="d-flex align-items-center">
                    <div className="image_width">
                      <img src={upi} alt="" />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss13" style={{ color: '#212529', fontWeight: 500, fontSize: '0.9em' }}>Withdraw to UPI</div>
                    </div>
                  </div>
                </div>
              </Link>
                <Link to="/withdraw-amount">
                  <div className="add-fund-box d-flex align-items-center mt-4">
                    <div className="d-flex align-items-center"><img width="48px" src={bank} alt="" />
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss13" style={{ color: '#212529', fontWeight: 500, fontSize: '0.9em' }}>Bank Transfer</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              :
              <div className="mt-3" style={{ opacity: 1 }}><Link to="/withdraw-amount-upi">
                <div className="add-fund-box d-flex align-items-center mt-4" >
                  <div className="d-flex align-items-center">
                    <div className="image_width">
                      <img src={upi} alt="" />
                    </div>
                    <div className="d-flex justify-content-center flex-column ml-4">
                      <div className="jss13" style={{ color: '#212529', fontWeight: 500, fontSize: '0.9em' }}>Withdraw to UPI</div>
                    </div>
                  </div>
                </div>
              </Link>
                <Link to="/withdraw-amount-bank">
                  <div className="add-fund-box d-flex align-items-center mt-4">
                    <div className="d-flex align-items-center"><img width="48px" src={bank} alt="" />
                      <div className="d-flex justify-content-center flex-column ml-4">
                        <div className="jss13" style={{ color: '#212529', fontWeight: 500, fontSize: '0.9em' }}>Bank Transfer</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdrawamount;

