import React,{ useEffect } from "react";
import { createRoot } from "react-dom/client";
import "./App.css";
import "./vplay.css";
import "./style.css";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
  createRoutesFromElements,
} from "react-router-dom";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import ReactDOM from "react-dom";
import Index from './routes/Index';
import Login from './routes/Login';
import Game from './routes/Game';
import Navbar from "./components/Navbar";
import Dashbord from "./routes/Dashbord";
import Rightsidebar from "./routes/Rightsidebar";
import Profile from "./routes/Profile";
import Addmoney from './routes/Addmoney';
import Sendmoney from './routes/Sendmoney';
import Gameshistory from './routes/Gameshistory';
import Topplayers from './routes/Topplayers';
import Transactionhistory from './routes/Transactionhistory';
import TransactionhistoryReffer from './routes/TransactionhistoryReffer';
import Referearn from './routes/Referearn';
import Notification from './routes/Notification';
import Support from './routes/Support';
import Addfund from './routes/Addfund';
import Refferamount from './routes/Refferamount';
import Withdrawamount from './routes/Withdrawamount';
import LudoClassic from './routes/LudoClassic';
import LudoClassic_second from './routes/LudoClassic_second';
import LudoPopular from './routes/LudoPopular';
import Termcondition from './routes/Termcondition';
import Refundpolicy from './routes/Refundpolicy';
import Privacypolicy from './routes/Privacypolicy';
import Contactus from './routes/Contactus';
import Responsiblegaming from './routes/Responsiblegaming';
import Rules from './routes/Rules';
import Infoconditions from './routes/Infoconditions';
import Withdrawbank from './routes/Withdrawbank';
import Withdrawupi from './routes/Withdrawupi';
import Kyc from "./routes/Kyc";
import Snake_and_Ladder_ulta from "./routes/Snake_and_Ladder_ulta";
import Snake_and_Ladder_sidha from "./routes/Snake_and_Ladder_sidha";
import RoomCode from "./routes/Roomcode";
import RoomCodePopular from "./routes/Roomcodepopular";
import Transactionhistorypending from "./routes/Transactionhistorypending";
import Battlechat from "./routes/Battlechat";
import ReactPWAPrompt from 'react-pwa-installer-prompt';
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";

const segment = window.location.pathname.split("/");
console.warn(segment[1])
if(segment[1] == 'login'){
  ReactDOM.render(
    <Router>
      <Routes>
      <Route path="/login" element={<Login />} />
      </Routes>
    </Router>,
  
    document.getElementById("root")
  );
}else{
  const AppLayout = () => (
    <>
      <Navbar />
      <Outlet />
      <Rightsidebar />
    </>
  );
  
  
  const router = createBrowserRouter(
   
    createRoutesFromElements(
      <Route element={<AppLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashbord />} />
        <Route path="/dashbord" element={<Dashbord />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/kyc" element={<Kyc />} />
        <Route path="/vplay-bet" element={<Game />} />
        <Route path="/wallet" element={<><Addmoney /></>} />
        <Route path="/send-money" element={<><Sendmoney /></>} />
        <Route path="/game-history" element={<><Gameshistory /></>} />
        <Route path="/top-10-players" element={<><Topplayers /></>} />
        <Route path="/transaction-history" element={<><Transactionhistory /></>} />
        <Route path="/transaction-history-reffer" element={<><TransactionhistoryReffer /></>} />
        <Route path="/refer-earn" element={<><Referearn /></>} />
        <Route path="/notification" element={<><Notification /></>} />
        <Route path="/support" element={<><Support /></>} />
        <Route path="/add-fund" element={<><Addfund /></>} />
        <Route path="/reffer-amount" element={<><Refferamount /></>} />
        <Route path="/withdraw-amount" element={<><Withdrawamount /></>} />
        <Route path="/Ludo-Classic" element={<><LudoClassic /></>} />
        <Route path="/Ludo-Classic-second" element={<><LudoClassic_second /></>} />
        <Route path="/Ludo-Popular" element={<><LudoPopular /></>} />
        <Route path="/term-condition" element={<><Termcondition /></>} />
        <Route path="/privacy-policy" element={<><Privacypolicy /></>} />
        <Route path="/refund-policy" element={<><Refundpolicy /></>} />
        <Route path="/contact-us" element={<><Contactus /></>} />
        <Route path="/responsible-gaming" element={<><Responsiblegaming /></>} />
        <Route path="/rules" element={<><Rules /></>} />
        <Route path="/info-conditions" element={<><Infoconditions /></>} />
        <Route path="/withdraw-amount-upi" element={<><Withdrawupi /></>} />
        <Route path="/withdraw-amount-bank" element={<><Withdrawbank /></>} />
        <Route path="/Snake-and-Ladder-ulta" element={<><Snake_and_Ladder_ulta /></>} />
        <Route path="/Snake-and-Ladder-sidha" element={<><Snake_and_Ladder_sidha /></>} />
        <Route path="/Ludo-Classic/Roomcode" element={<><RoomCode /></>} />
        <Route path="/Ludo-Ulta/Roomcode" element={<><RoomCode /></>} />
        <Route path="/Ludo-Popular/Roomcodepopular" element={<><RoomCodePopular /></>} />
        <Route path="/Battlechat" element={<><Battlechat /></>} />
        <Route path="/Transactionhistorypending" element={<><Transactionhistorypending /></>} />
        
      </Route>
    )
  );
  
  createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />
  );
}
