
import React,{useEffect, useState} from "react";
import player from '../images/Avatar2.png';
import axios from "axios";

const Topplayers = () => {

  const [users, setUsers] = useState([]);

  
  useEffect(
    () => {
      loaduser();
      // alert('game')
      
    }, []
  );

  const loaduser = async () => {

    var data = '';
    const token = localStorage.getItem("token_store_vplay");

    var config = {
      method: 'get',
      url: 'https://api.vplay.bet/api/users/get-top-player',
      headers: {
        'x-access-token': token
      },
      data: data,
      // setdata:()=>{
      //   sessionStorage.setItem('token','eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NzksImlhdCI6MTY3NDc5NTQwNyw  iZXhwIjoxNzA2MzMxNDA3fQ.6t2xbDirZ_GOWuLReg9N2pbOS5-Ss3ohqXrFFTslNyk')
      // }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        console.log(objectRes.data);
        // alert(objectRes.data)
        setUsers(objectRes.data);
        console.log(users[2].id);
        // config.setdata();
      })
      .catch(function (error) {
        console.log(error);
      });
    }


  return (
    <>
     
      <div className="leftContainer" >
        <div className="main-area" style={{ paddingTop: '60px' }} >
        {users && users.map((user, index) => (
          <>
          <div className="d-flex px-4 py-3 list-item">
            <picture className="mr-3" scope="row" style={{ fontSize: '18px', color: 'black',display:"flex",
    justifyContent: "center",
    alignItems: "center",width:"20px"}}>{index+1}</picture><img className="border-50" height="40px" width="40px" src={player} alt="" />
            <div style={{ marginTop: '-7px' }}><span className="font-9 fw-800 d-flex" style={{ fontSize: '20px' }}>
              &nbsp;{user.vplay_id}</span><small className="d-flex font-7 " style={{ lineHeight: '7px' }}> &nbsp; Total
                Win Amount: <strong>{user.total_win_amount}</strong></small>
              <small className="d-flex font-7 mt-2" style={{ lineHeight: '7px' }}> &nbsp; Total Win Match:
                <strong>{user.total_win}</strong></small>
            </div>
             
          </div>  
          </>
          ))} 
        </div>
        </div>
      
      
    </>
  );
}

export default Topplayers;