
import { React, useState, useEffect } from "react";
import logos from '../images/vplaylogo.png'
import chrome from '../images/global-chrome.png'

const Rightsidebar = () => {
    useEffect(() => { 
        const handleContextMenu = (e) => {
          e.preventDefault();
        };
      
        const handleKeyDown = (e) => {
          if (e.key === "F12" || e.key === "F12" || e.keyCode === 123) {
            e.preventDefault();
          }
        };
      
        document.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("keydown", handleKeyDown);
      
        return () => {
          document.removeEventListener("contextmenu", handleContextMenu);
          document.removeEventListener("keydown", handleKeyDown);
        };
      }, []);

      const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(true);

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', (event) => {
                event.preventDefault();
                setDeferredPrompt(event);
            });
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt !== null) {
            deferredPrompt.prompt();

            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                    setShowInstallButton(false);
                } else {
                    console.log('User dismissed the A2HS prompt');
                }

                setDeferredPrompt(null);
            });
        }
    };
    return (
        <div className="">
            <div className="divider-y" />
            <div className="rightContainer bg_right">
                <div className="rcBanner flex-center">
                    <picture className="rcBanner-img-container">
                        <img src={logos} alt="" title="Vplay Ludo" width="100px" hight="20px" />
                    </picture>
                    <div className="rcBanner-text">Vplay <span className="rcBanner-text-bold">Win Real Cash!</span></div>
                    <div className="rcBanner-footer">For best experience, open&nbsp;<a href="#!" style={{ color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none' }}>Vplay.bet</a>&nbsp;on&nbsp;<img src={chrome} alt="" />&nbsp;chrome mobile</div>
            <div className="text-center app_download">
                {showInstallButton &&    <button  className="btn bg-primary btn-ronded text-light" onClick={handleInstallClick}>Install Web Application</button>}
                </div>
                </div>
            </div>
        </div>
    );
}
export default Rightsidebar;