import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Kyc = () => {
  const [selectedCard, setSelectedCard] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [successMessage, setSuccessMessage] = useState("");
  const [frontPic, setFrontPic] = useState(null);
  const [backPic, setBackPic] = useState(null);
  const [error, setError] = useState("");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [users, setUsers] = useState([]);
  let userid = localStorage.getItem("userid");

  const handleCardChange = (event) => {
    setSelectedCard(event.target.value);
    setInputValue('');
    setCurrentStep(1);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    loaduser();
  }, []);
  const loaduser = async () => {
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/get-profile',
      headers: {
        'x-access-token': token
      },
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes.data[0]);

      })
      .catch(function (error) {
        console.log(error);
      });

  }
  const handleNext = async () => {
    let errorMessage = null;
    if (!inputValue) {
      errorMessage = "Please enter a value.";
    } else if (selectedCard === "UID" && !isValidAadhar(inputValue)) {
      errorMessage = "Please enter a valid Aadhar number.";
    } else if (selectedCard === "DL" && !isValidDL(inputValue)) {
      errorMessage = "Please enter a valid Driver's License number.";
    } else if (selectedCard === "VID" && !isValidVoterID(inputValue)) {
      errorMessage = "Please enter a valid Voter ID number.";
    }


    if (errorMessage) {
      console.error(errorMessage);
      setError(errorMessage);
      return;
    }

    try {
      let requestBody = new FormData();
      requestBody.append("document_name", selectedCard);
      requestBody.append("userid", userid);
      if (selectedCard === "UID") {
        requestBody.append("adhar", inputValue);
      } else if (selectedCard === "DL") {
        requestBody.append("dl", inputValue);
      } else if (selectedCard === "VID") {
        requestBody.append("voter", inputValue);
      }

      const response = await fetch(
        "https://vplay.bet/Admin/public/complete-kyc-phase1-react",
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
          },
          body: requestBody,
          mode: "no-cors",
        }
      );

      setSuccessMessage("Data saved successfully!");
      setCurrentStep(currentStep + 1);
    } catch (error) {
      // Handle the error here if needed
    }
  };

  const isValidAadhar = (value) => {

    return /^\d{12}$/.test(value);
  };

  const isValidDL = (value) => {

    return true;
  };

  const isValidVoterID = (value) => {

    return true;
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep);
  };


  const handleNext1 = async () => {
    let newErrors = {};
    if (!firstName) {
      newErrors.firstName = 'First Name is required';
    }
  
    if (!lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!dob) {
      newErrors.dob = 'Date of Birth is required';
    }
    if (!selectedState) {
      newErrors.selectedState = 'State selection is required';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    try {
      let requestBody = new FormData();
      requestBody.append('firstName', firstName);
      requestBody.append('lastName', lastName);
      requestBody.append('dob', dob);
      requestBody.append('state', selectedState);
      requestBody.append('userid', userid);

      const response = await fetch(
        "https://vplay.bet/Admin/public/complete-kyc-phase-second-react",
        {
          method: "POST",
          headers: {

            // "Content-Type": "application/json",
          },
          body: requestBody,
          mode: "no-cors",
        }
      );
      setSuccessMessage("Data saved successfully!");
      setCurrentStep(currentStep + 1);
    } catch (error) {
      // Handle the error here if needed
    }
  };


  const handleFrontPicChange = (event) => {
    setFrontPic(event.target.files[0]);
  };

  const handleBackPicChange = (event) => {
    setBackPic(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!frontPic || !backPic) {
      alert('All fields are required');
      return;
    }
  
    const formData = new FormData();
    formData.append('frontPic', frontPic);
    formData.append('backPic', backPic);
    formData.append('userid', userid);
  
    try {
      const response = await fetch('https://vplay.bet/Admin/public/complete-kyc-phase-third-react', {
        method: 'POST',
        body: formData,
        mode: "no-cors",

      });
      
      // const data = await response.json();
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Kyc Submit Successfully!</strong>,
        icon: 'success',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 7000,

      }).then((result) => {
        window.location.href = "/profile";
        // location.href = "/";
      })
      // console.log(data.message);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const storedStep = localStorage.getItem("currentStep");
    if (storedStep) {
      setCurrentStep(Number(storedStep));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentStep", currentStep);
  }, [currentStep]);


  const isStepComplete = (stepNumber) => {
    return stepNumber < currentStep;
  };
  
  return (
    <div className="width_400">
      <div className="container" style={{ marginTop: "100px" }}>
        {currentStep === 1 && (
          <div>
            <label htmlFor="cardSelect">Select Card Type:</label>
            <select id="cardSelect" className="form-control" value={selectedCard} onChange={handleCardChange}>
              <option value="">Select</option>
              <option value="UID">Aadhar Card</option>
              <option value="DL">Driving Lience</option>
              <option value="VID">Voter ID</option>
            </select>

            {selectedCard === 'UID' && (
              <div>
                <div className="col-lg-12 p-0">
                  <div className="form-group">
                    <input
                      type="text"
                      id="aadharInput"
                      className="form-control form_custum mt-2"
                      value={inputValue}
                      placeholder="Enter Aadhar Card Number:"
                      onChange={handleInputChange}
                    />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                  </div>
                </div>
                <button className="btn btn-primary w-100 btn_click_next" onClick={handleNext}>Next</button>
              </div>
            )}

            {selectedCard === 'DL' && (
              <div>
                <div className="col-lg-12  p-0">
                  <div className="form-group">
                    <input
                      type="text"
                      id="panInput"
                      className="form-control form_custum mt-2"
                      placeholder="Enter DL Card Number"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                  </div>
                </div>
                <button className="btn btn-primary w-100 btn_click_next" onClick={handleNext}>Next</button>
              </div>
            )}

            {selectedCard === 'VID' && (
              <div>
                <div className="col-lg-12  p-0">
                  <div className="form-group">
                    <input
                      type="text"
                      id="voterInput"
                      className="form-control form_custum  mt-2"
                      placeholder="Enter Voter ID"
                      value={inputValue}
                      onChange={handleInputChange}
                    />
                    {error && <span style={{ color: "red" }}>{error}</span>}
                  </div>
                </div>
                <button className="btn btn-primary w-100 btn_click_next" onClick={handleNext}>Next</button>
                {isStepComplete(1) && <p>Step 1 is complete</p>}
              </div>
            )}
          </div>
        )}

        {currentStep === 2 && (
          
          <div>
            {isStepComplete(1) && <p>Step 1 is complete</p>}
            <div className="col-lg-12 p-0">
              <div className="form-group">
                <input
                  type="text"
                  id="firstName"
                  placeholder="First Name:"
                  className="form-control form_custum mt-0"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {error.firstName && <span style={{ color: "red" }}>{error.firstName}</span>}
              </div>
            </div>
            <br />
            <div className="col-lg-12 p-0">
              <div className="form-group">

                <input
                  type="text"
                  id="lastName"
                  placeholder="Last Name:"
                  className="form-control form_custum  mt-0"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
               {error.lastName && <span style={{ color: "red" }}>{error.lastName}</span>}
              </div>
            </div>
            <br />
            <div className="col-lg-12 p-0">
              <div className="form-group">
                <label htmlFor="dob" className="dateofbirth">Date of Birth:</label>
                <input
                  type="date"
                  id="dob"
                  placeholder="Date Of Birth:"
                  className="form-control form_custum mt-0"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
                {error.dob && <span style={{ color: "red" }}>{error.dob}</span>}
              </div>
            </div>
            <br />
            <div>
              <label htmlFor="stateSelect">Select State:</label>
              <select
                id="stateSelect"
                className="form-control custom_select mt-0"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                <option value="Daman and Diu">Daman and Diu</option>
                <option value="Delhi">Delhi</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
            <br />
            <div className="Previousnext d-flex justify-content-between mt-3 btn_click_nextsec">
              <button className="btn btn-primary bg_secondary_second" onClick={handlePrevious}>Previous</button>
              <button className="btn btn-primary" onClick={handleNext1}>Next</button>
            </div>
           
          </div>
        )}

        {currentStep === 3 && (
          <div>
            {isStepComplete(2) && <p>Step 2 is complete</p>}
            <label htmlFor="uploadFile">Upload File:</label>
            <div className="dropzone-wrapper">
              <div className="dropzone-desc">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <p className="uploadphoto">Upload Front Photo</p>
              </div>
              <input type="file" name="frontPic" id="frontPic" className="form-control dropzone" onChange={handleFrontPicChange} />
              {frontPic && <p>Front Photo: {frontPic.name}</p>}
            </div>
            <br /><br />
            <div className="dropzone-wrapper">
              <div className="dropzone-desc">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <p className="uploadphoto">Upload back Photo</p>
              </div>
              <input type="file" name="backPic" id="backPic" className="form-control dropzone" onChange={handleBackPicChange} />
              {backPic && <p>Back Photo: {backPic.name}</p>}
            </div>
            <div className="Previousnext d-flex justify-content-between mt-3 btn_click_nextsec">
              <button className="btn btn-primary bg_secondary_second" onClick={handlePrevious}>Previous</button>
              <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

export default Kyc;
