import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import vs from '../images/versus.png'
import user1 from '../images/Avatar2.png'
import rupeeIcon from '../images/global-rupeeIcon.png'
import ios from '../images/ios.png'
import android from '../images/android.jpg'
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Moment from 'moment';
import io from "socket.io-client";
import loader from '../images/dancingloader.gif'
import $ from 'jquery';
import copy from 'copy-to-clipboard';

import imageCompression from 'browser-image-compression';
const Battlechat = () => {

  const [gamebat, setGamebat] = useState(
    {
      game_url: "Snake-and-Ladder-sidha",
      amount: '',
      label: "",
      room: "",
    }
  )
  const [gameid, setgameid] = useState({

    "game_id": "3"
  })


  const [users, setUsers] = useState([]);
  const [roomcode, setRoomCode] = useState([]);
  const [RoomCodePerfect, setRoomCodePerfect] = useState([]);
  const [battleId, setBattleId] = useState('null');
  const [lobbyId, setLobbyId] = useState('null');
  const [battle_id, setbattle_id] = useState('null');
  const [joinerId, setJoinerId] = useState('null');
  const [createrId, setCreatorId] = useState('null');
  const [creatorresult, setCreatorResult] = useState('');
  const [joinerresult, setJoinerResult] = useState('');
  const [BatAmount, setBatAmount] = useState('');
  const [CretaerName, setCreaterName] = useState('');
  const [JoinerName, setJoinerName] = useState('');
  const [useridd, setuseridd] = useState('');

  // alert(joinerId);

  useEffect(() => {
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');
    // alert(idParam);
    if (idParam) {
      setBattleId(idParam);
    }
    // copy('Text', {
    //   debug: true,
    //   message: 'Press #{key} to copy',
    // });
  }, []);

  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  }

  useEffect(
    () => {
      loaduser();
      $('.loader_roomcode').hide();
      $('.overlay_div').hide();
      const userid = localStorage.getItem("userid");
      setuseridd(userid);
      multiple_auth_chk();
    }, []
  );
  const multiple_auth_chk = async () => {


    var data = '';
    const ipaddress = localStorage.getItem("ipaddress");
    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
      headers: {
        'x-access-token': token
      },
      data: { ipaddress: ipaddress }
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        // console.warn(objectRes.data[0]['total']);
        var totlaipp = objectRes.data[0]['ip'];
        // alert(totlaipp);
        if (totlaipp != ipaddress) {
          localStorage.setItem('token_store_vplay', ' ');
          localStorage.setItem('userid', ' ');
          window.location.href = '/login';
        }
        console.warn(totlaipp);
        // setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });
    if (!token) {
      window.location.href = '/login';
      // exist();
    }
  }

  const loaduser = async () => {
    const url = new URL(window.location.href);
    const idParam = url.searchParams.get('id');

    // console.warn(idParam);
    const token = localStorage.getItem("token_store_vplay");
    const config = {
      method: "post",
      url: "https://api.vplay.bet/api/users/room-code-dta",
      headers: {
        "x-access-token": token
      },
      data: { id: idParam }
    };

    try {
      const response = await axios(config);
      const res = JSON.stringify(response.data);
      const objectRes = JSON.parse(res);
      setUsers(objectRes.data);

      if (objectRes.data.length > 0) {
        // alert(objectRes.data[0].LOBBY_ID);
        setRoomCode(objectRes.data[0].LOBBY_ID);
        setRoomCodePerfect(objectRes.data[0].roomcode);
        setJoinerId(objectRes.data[0].joiner_id);
        setCreatorId(objectRes.data[0].creator_id);
        setLobbyId(objectRes.data[0].LOBBY_ID);
        setbattle_id(objectRes.data[0].battle_id);
        setCreatorResult(objectRes.data[0].creator_result);
        setJoinerResult(objectRes.data[0].joiner_result);
        setBatAmount(objectRes.data[0].amount);
        setCreaterName(objectRes.creater_data[0].vplay_id);
        setJoinerName(objectRes.joiner_data[0].vplay_id);
        getMessages(objectRes.data[0].battle_id);

      }
    } catch (error) {
      console.log(error);
    }
  };

  const [fileData, setFileData] = useState('null');
  const [selectedOption, setSelectedOption] = useState('');
  const [showFileUpload, setShowFileUpload] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('false');
  const [validationErrors, setValidationErrors] = useState({});
  const player_id = localStorage.getItem("userid");
  const [createId, setCreateId] = useState('null');
  const [room, setRoom] = useState('');


  useEffect(
    () => {
      // document.getElementById('canceldata').removeAttribute("style");
      $('.canceldata').hide();
    }, []
  );

  const handleOptionChange = (e) => {
    const value = e.target.value;
    // alert(value);
    if (value == 'cancel') {
      $('.canceldata').show();
    } else {
      $('.canceldata').hide();
    }
    if (value == 'win') {
      $('.imageouter').show();
    } else {
      $('.imageouter').hide();
    }
    if (value == 'lost') {
      alert('Are you sore lose game')
    }
    setSelectedOption(value);
    setShowFileUpload(value === "win");
    setShowMessage(value === "lost");
    setMessage(value === "cancel");
    setFileData(null);
  };

  const handleTextareaChange = (e) => {
    setMessage(e.target.value);
  };

  const oninputchanges = (e) => {
    setRoom({ ...room, [e.target.name]: e.target.value });
  }
  const handleSubmitRoomcode = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_store_vplay");
    try {
      // Reset validation errors
      setValidationErrors({});
      // Check if any of the required fields are empty
      const errors = {};
      // console.warn(room['room']);
      // alert(battleId);
      if (!room) {
        errors.room = "Room code is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Check if any field has null or undefined value
      if (room === null) {
        return;
      }
      // $('#redeemNow').attr('disabled', 'true');

      var config = {
        method: 'post',
        url: 'https://api.vplay.bet/api/users/room-code-update',
        headers: {
          'x-access-token': token
        },
        data: room,
        data: { roomcode: room['room'], id: battleId }
        // data: {battleid}
      };
      axios(config).then((response) => {
        // console.warn(response.data.status);
        loaduser();
      }).catch(function (error) {
        console.log(error);
      });

    }

    catch (error) {
      console.error("Error adding bank:", error);
      // Handle error (e.g., display error message)
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // alert('sdsadsdsd');
    // return
    if (!selectedOption) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Please select one of the options (I won, I lose, or Cancel)</strong>,
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2000,

      })
      // console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;

    }
    if (!fileData) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Please Upload Image !</strong>,
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 4000,

      })
      return;
    }
    $('.sbumitbuttondis').attr('disabled', 'true');
    $('.loader_roomcode').show();

    $('.overlay_div').show();

    const apiUrl = "https://vplay.bet/Admin/public/battle-result-store-react";
    const formData = new FormData();
    formData.append('image', fileData);
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('userid', player_id);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => {
        // if (response.data.message == true) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: <strong>Declear Successfully!</strong>,
          icon: 'success',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,

        }).then((result) => {
          window.location.href = "/";
          // location.href = "/";
        })
        // } else {
        //   const MySwal = withReactContent(Swal)
        //   MySwal.fire({
        //     title: response.data.message,
        //     icon: 'error'
        //   })
        // }
      });


    } catch (error) {
      console.error("An error occurred while making the API request:", error);

    }


  };


  const handleSubmit1 = async (e) => {
    e.preventDefault();

    // alert(battleId);

    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = "https://vplay.bet/Admin/public/battle-result-store-react";
    const formData = new FormData();
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('userid', player_id);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => {
        // if (response.data.message == true) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: <strong>Declear Successfully!</strong>,
          icon: 'success',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,

        }).then((result) => {

          window.location.href = "/";
        })

      });
    } catch (error) {
      console.error("An error occurred while making the API request:", error);
    }
  };


  const [message1, setMessage1] = useState('');
  const [chatHistory, setChatHistory] = useState([]);

  useEffect(() => {
    // getMessages();
    // alert('pppp');

    getMessages();


    const socket = io('http://103.129.97.243:3001', {
      // auth: { token },
    });
    const interval = setInterval(() => {

      getMessages();
    }, 3000);

    socket.on('connect', () => {
      getMessages();
    });

    socket.on('user_data', (data) => {
      console.log(data);
    });

    socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    // Clean up socket connection when the component unmounts
    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, []);


  const getMessages = async (roomcode) => {

    var battle_idset = roomcode;
    // alert(battle_idset);
    const token = localStorage.getItem("token_store_vplay");
    const formData = new FormData();
    formData.append("lobbyid", battle_idset);
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/message-get-game',
      headers: {
        'x-access-token': token
      },
      data: {
        'lobbyid': battle_idset,
      }
      // data:formData

    };

    axios(config)
      .then(function (response) {
        // alert('hello');
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        $('.chathistory').animate({ scrollTop: 9999 });
        setChatHistory(objectRes.data);
      })
  };


  const handleSendMessage = async () => {
    try {

      const formData = new FormData();
      formData.append("userid", player_id);
      formData.append("lobbyid", battle_id);
      formData.append("message", message1);
      const response = await fetch("https://vplay.bet/Admin/public/message-send-game", {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      try {
        loaduser();
        const data = response.data.data;
        // console.warn(data);
        // alert('ppppp');
        // alert('data');
        // var sert = " ";
        // $('.CLREARRT').val(' ');
        // setMessage1(sert);
        getMessages();
        setChatHistory([...chatHistory, { id: data.id, sender: "sent", message: message1 }]);
      }
      catch (error) {
        // alert("Error parsing response as JSON");
        console.error("Error parsing response as JSON:", error);
      }

    } catch (error) {
      // alert("Error sending message");
      console.error("Error sending message:", error);
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();


    if (message == true) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: <strong>Please Enter message!</strong>,
        icon: 'error',
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2000,

      }).then((result) => {

        // window.location.href = "/";
      })
      return;
    }
    // alert(message);
    document.getElementById('disablehandleSubmit2').style.display = 'none';
    $('.loader_roomcode').show();
    $('.overlay_div').show();
    if (!selectedOption) {
      console.error("Please select one of the options (I won, I lose, or Cancel)");
      return;
    }
    const apiUrl = "https://vplay.bet/Admin/public/battle-result-store-react";
    const formData = new FormData();
    formData.append('battleResult', selectedOption);
    formData.append('battleId', battleId);
    formData.append('userid', player_id);
    formData.append('cancelMessage', message);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData,
      }).then((response) => {
        // if (response.data.message == true) {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: <strong>Declear Successfully!</strong>,
          icon: 'success',
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000,

        }).then((result) => {

          window.location.href = "/";
        })

      });
    } catch (error) {
      console.error("An error occurred while making the API request:", error);

    }
  };
  const copyContent = async () => {
    let copyText = document.getElementById('myText').innerHTML;
    copy(copyText);
  }
  useEffect(
    () => {
      loaduser();


      var readURL = function (input) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();

          reader.onload = function (e) {
            // $('.profile-pic').show();
            $('.profile-pic1').attr('src', e.target.result);
          }

          reader.readAsDataURL(input.files[0]);
          // setFileData(input.files[0]);
          // profileupdate(input.files[0]);
        }
      }


      $("#imageupload").on('change', function () {
        readURL(this);
      });

      $(".upload-button").on('click', function () {
        $("#imageupload").click();
      });

    }, [true]
  );
  return (
    <>
      <div className="container"></div>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">

            <div className="text-center">
              <div className="roomcode">
                <div className="d-flex justify-content-center width_300">
                  <div className="user1">
                    <img className="img-fluid" src={user1} style={{ width: "50px" }} />
                    <span> {CretaerName}</span>
                  </div>
                  <div className="vs">
                    <img src={vs} className="img-fluid" />
                  </div>
                  <div className="user2">
                    <img className="img-fluid" src={user1} style={{ width: "50px" }} />
                    <span>{JoinerName} </span>
                  </div>
                </div>
                <div className="amountplay">
                  <p className="playing"><span style={{ color: "black", opacity: "0.8" }}>Playing for </span><img src={rupeeIcon} className="img-fluid money_width" /><span className="batamount"> {BatAmount}</span></p>
                </div>

              </div>
              <hr></hr>

              {/* <div className="codebg">
                <h5>Room Code</h5>
                {roomcode == null ?
                  <div className="row">
                    <div className="col-md-8">
                      <input type="text" class="form-control" id="roomcodeper" name="room" onChange={oninputchanges} />
                      {validationErrors.room && <p>{validationErrors.room}</p>}
                    </div>
                    <div className="col-md-4">
                      <button className="btn btn-primary" onClick={handleSubmitRoomcode}>Send</button>
                    </div>
                  </div>
                  :
                  <div>
                    <div id="myText">{roomcode}</div>
                    <button class="btn btn-primary" onClick={copyContent}>Copy!</button>
                  </div>
                }
                </div> */}

              <div className="roomCode cxy flex-column" style={{ height: "500px", width: "100%" }}>
                <div className="chathistory bg-white rounded">
                  {chatHistory.map((msg) => (
                    useridd == msg.userid ? (
                      <div
                        key={msg.id}

                        className={`message ${msg.sender === 'sent' ? 'left' : 'right'}`}
                      >
                        {msg.message}
                        <br />
                        <div className="time_chat">
                          {Moment(msg.created_at).format('h:mm')}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={msg.id}
                        className={`message ${msg.sender === 'sent' ? 'right' : 'left'}`}
                      >
                        {msg.message}
                        <div className="time_chat">
                          {Moment(msg.created_at).format('h:mm')}
                        </div>
                      </div>
                    )
                  ))}
                </div>
                <div className="d-flex justify-content-between2" style={{ marginTop: "15px" }}>
                  <input type="text" value={message1} onChange={(e) => setMessage1(e.target.value)} className="form-control CLREARRT" />
                  <button className="btn btn-primary" onClick={handleSendMessage}>Send</button>
                </div>
              </div>

            </div>

          </div>
        </div>



      </div>
      <div className="width_status">
        <div class="rules"><span class="cxy mb-1"><u>Game Rules</u></span>
          <ol class="list-group list-group-numbered">
            <li class="list-group-item">Record every game while playing.</li>
            <li class="list-group-item">For cancellation of game, video proof is necessary.</li>
            <li class="list-group-item">50 Penality will be charged for updating wrong result.</li>
            <li class="list-group-item">25 Penality will be charged for not updating result.</li>
          </ol>
        </div>
      </div>

      <div className="width_status position-relative">
        <div className="match-status-border row">
          <div className="matchstaus" style={{ fontSize: 20, fontWeight: 800 }}>Match Status</div>
          <div className="border_width_room">
            {joinerId == player_id && joinerresult != null ?
              <div className="text-center">
                <p>Result All Ready declare {joinerresult}</p>
              </div>
              : createrId == player_id && creatorresult != null ?
                <div className="text-center">
                  <p>Result All Ready declare {creatorresult}</p>
                </div>
                : <form className="form-room text-center">
                  <label className="radio-inline">
                    <input type="radio" value="win" checked={selectedOption === "win"} onChange={handleOptionChange} /> I won </label>
                  <label className="radio-inline">
                    <input type="radio" value="lost" checked={selectedOption === "lost"} onChange={handleOptionChange} /> I lose </label>
                  <label className="radio-inline">
                    <input type="radio" value="cancel" checked={selectedOption === "cancel"} onChange={handleOptionChange} />
                    Cancel
                  </label>

                  {/* File Upload */}
                  {/* {showFileUpload && ( */}
                  <div className="imageouter">
                    <div className="file_upload">
                      <label> <input type="file" className="file-upload1" id="imageupload" onChange={(e) => setFileData(e.target.files[0])} /></label>
                    </div>
                    <br /><br />
                    <img className="profile-pic1" height="400px" width="200px" />
                    <br /><br />
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green sbumitbuttondis" onClick={handleSubmit}>
                        Submit Details
                      </button>
                    </div>
                  </div>

                  {/* )} */}

                  {/* Show message */}
                  {showMessage && (
                    <>
                      <div className="text-center">
                        <p>Better luck next time!</p>
                      </div>
                      <div className="btn_details">
                        <button type="submit" className="btn btn-green sbumitbuttondis" onClick={handleSubmit1}>
                          Submit Details
                        </button>
                      </div>
                    </>

                  )}
                  {/* {message && ( */}
                  <div className="canceldata">
                    <div className="text-center">
                      {/* <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setMessage(e.target.value)}  ></textarea> */}
                      <div>
                        <h3 className="labelselect">Select an Option Cancel:</h3>
                        <select className="form-control-custom" onChange={(e) => setMessage(e.target.value)}>
                          <option selected>Please Select</option>
                          <option>No Room Code</option>
                          <option>Not Joined</option>
                          <option>Not Playing</option>
                          <option>Don't Want to Play</option>
                          <option>Aponenet Abusing</option>
                          <option>Gamed Not Started</option>
                          <option>Other</option>
                        </select>
                        {/* <p>Selected Option: {selectedOption}</p> */}
                      </div>
                    </div>
                    <br />
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green sbumitbuttondis" id="disablehandleSubmit2" onClick={handleSubmit2}>
                        Submit Details
                      </button>
                    </div>
                  </div>

                  {/* )} */}

                </form>}
            {/* {joinerId == player_id && joinerresult != null ? (
              <div className="text-center">
                <p>Result All Ready declare {joinerresult}</p>
              </div>
            ) : (
             
              <form className="form-room text-center">
                <label className="radio-inline">
                  <input type="radio" value="win" checked={selectedOption === "win"} onChange={handleOptionChange} /> I won </label>
                <label className="radio-inline">
                  <input type="radio" value="lost" checked={selectedOption === "lost"} onChange={handleOptionChange} /> I lose </label>
                <label className="radio-inline">
                  <input type="radio" value="cancel" checked={selectedOption === "cancel"} onChange={handleOptionChange} />
                  Cancel
                </label>

                {showFileUpload && (
                  <>
                    <div className="file_upload">
                      <label> <input type="file" onChange={(e) => setFileData(e.target.files[0])} /></label>
                    </div>
                    <br /><br />
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green" onClick={handleSubmit}>
                        Submit Details
                      </button>
                    </div>
                  </>

                )}

                {showMessage && (
                  <>
                    <div className="text-center">
                      <p>Better luck next time!</p>
                    </div>
                    <div className="btn_details">
                      <button type="submit" className="btn btn-green" onClick={handleSubmit1}>
                        Submit Details
                      </button>
                    </div>
                  </>

                )}

               
              </form>

            )} */}
          </div>
        </div>
        <div className="overlay_div"></div>
        <div className="loader_roomcode">
          <img src={loader}></img>
        </div>
      </div>

      {/* <div className="overlay_div"></div>
      <div className="loader_roomcode">
      <img src={loader}></img>
      </div> */}
    </>
  );
};
export default Battlechat;