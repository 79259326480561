import React from "react";
import { Outlet, Link } from "react-router-dom";
const Refundpolicy = () => {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">
            <h2>Refund Policy</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <h4>Refund Policy:</h4>
                <ol className="rules-list">
                  Thanks for being a patron with Vplay. If you are not entirely satisfied with your
                  subscription, we are here to help.
                  <h5>Refund</h5>
                  Once we receive your Refund request, we will inspect it and notify you on the status of
                  your refund.<br /><br />
                  If your refund request is approved, we will initiate a refund to your credit card (or
                  original method of payment) within 7 working days. You will receive the credit within a
                  certain amount of days, depending on your card issuer's policies.<br /><br />
                  In case of unforeseen technical glitch, Vplay would refund subscription upon reviewing
                  the complaint. Final decision lies with the company.
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Refundpolicy;