
import React, { useEffect, useState } from "react";
import rup from '../images/global-rupeeIcon.png'
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const TransactionhistoryReffer = () => {
const [users, setUsers] = useState([]);
const [showModal, setShowModal] = useState(false);



    useEffect(
        () => {
            loaduser();
            // alert('game')

        }, []
    );

    const loaduser = async () => {
        const token = localStorage.getItem("token_store_vplay");

        var data = '';

        var config = {
            method: 'post',
            url: 'https://api.vplay.bet/api/users/transaction-history-reffer',
            headers: {
                'x-access-token': token
            },
            data: data,   
            // setdata: () => {
            //     sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs')
            // }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                console.log(objectRes.data);
                // alert(objectRes.data)
                setUsers(objectRes.data);
                console.log(users[2].id);
                // config.setdata();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ========viev======
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="leftContainer">
                <div>
                    <div className="main-area" style={{ paddingTop: '60px' }} />
                    {/* <div className="container">
                        <a href="#" className="text-light">
                            <div className="btn btn-primary w-100 my-2 ">
                                Referral History</div>
                        </a>
                    </div> */}
                </div>
                {users && users.map((user, index) => (
                    <>
                <div key={index} className="w-100 py-3 d-flex align-items-center list-item">
                    <div className="center-xy list-date mx-2">
                        <div>{user.day}{user.month}</div><small>{user.paying_time}</small>
                    </div>
                    <div className="overlay" id="card-1">
                        <div className="card-content">
                            {/* <a href="#close" className="close" onClick={handleShow}>x</a>
                            <div className="card__info" id="showhistory" >
                            </div> */}
                        </div>
                    </div>
                    <div className="mx-3 d-flex list-body">
                        <div className="d-flex align-items-center" />
                        <div className="d-flex flex-column font-8">
                            Refferal Bonus.
                            <div className="games-section-headline">Bonus Added By:{user.GamePartnerId}</div>
                        </div>
                    </div>
                    <div className="right-0 d-flex align-items-end pr-3 flex-column">
                        <div className="d-flex float-right font-8">
                            <div className="text-success">
                                (+)
                            </div>
                            <picture className="ml-1 mb-1"><img height="21px" width="21px" src={rup} alt="" /></picture><span className="pl-1">{user.amount}</span>
                        </div>
                        {/* <div className="games-section-headline" style={{ fontSize: '0.6em' }}>Closing Balance: {user.closing_balance}</div> */}
                    </div>
                </div>
                </>
                ))}

                
                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Body> <div className="card-content">
                        <a href="#close" className="close" onClick={handleClose}>x</a>
                        <div className="card__info" id="showhistory">
                            <div>
                                <h3 style={{ paddingTop: '2%' }}>Withdraw</h3>
                                <hr />
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Order ID</strong></div>
                                    {/* <div className="col-md-6">order_{user.order_id}</div> */}
                                    <div className="col-md-6">order_769445</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Request Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Processed Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row align-items-center" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Status</strong></div>
                                    <div className="col-md-6"><button className="btn btn-success py-0">PAID</button></div>
                                </div>
                                <br />
                                <h5>Payment Details</h5>
                                <div className="row pb-3" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-4">UPI</div>
                                    <div className="col-md-8">2200</div>
                                </div>
                            </div>
                        </div>
                    </div></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                
            </div>
        </>

    );
}

export default TransactionhistoryReffer;