import React, { useState, useEffect } from "react";
import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import money from '../images/send-money.png'
import rup from '../images/global-rupeeIcon.png'

const Sendmoney = () => {
  // const [mobile, setMobile] = useState("");
  // const [amount, setAmount] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  // Api  Winning Cash Amount
  const [users, setUsers] = useState([]);
  useEffect(
    () => {
      loaduser();
    }, []
  );

  const loaduser = async () => {


    var data = '';

    const token = localStorage.getItem("token_store_vplay");
    var config = {
      method: 'post',
      url: 'https://api.vplay.bet/api/users/header-amount',
      headers: {
        'x-access-token': token
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        const res = JSON.stringify(response.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  // Api Withdraw Amount
  const [gamebat, setGamebat] = useState(
    {
      amount: '',
      mobile: ""
    }
  )
  const { amount, mobile } = gamebat;
  const oninputchange = (e) => {
    setGamebat({ ...gamebat, [e.target.name]: e.target.value });
  }
  const handleAddBankSubmit = async () => {
    const token = localStorage.getItem("token_store_vplay");
    try {
      // Reset validation errors
      setValidationErrors({});

      // Check if any of the required fields are empty
      const errors = {};

      if (!(mobile.match('[0-9]{10}'))) {
        errors.mobile = "Please provide valid phone number";
      } 

      if (!amount) {
        errors.amount = "Amount is required";
      }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      // Check if any field has null or undefined value
      if (
        amount === null ||
        mobile === null

      ) {
        return;
      }

      $('#redeemNow').attr('disabled', 'true');

      var config = {
        method: 'post',
        url: 'https://api.vplay.bet/api/users/money-send-friend',
        headers: {
          'x-access-token': token
        },
        data: gamebat
      };
      axios(config).then((response) => {
        $('#redeemNow').attr('disabled', 'false');
        console.warn(response);
        if (response.data.status == 1) {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: <strong>Amount is Successfully!</strong>,
            icon: 'success',
            timer: 1500
          })
            .then((result) => {
              window.location.reload();
            })
        } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
            title: response.data.message,
            icon: 'error'
          })
        }
      }).catch(function (error) {
        console.log(error);
      });

    }

    catch (error) {
      console.error("Error adding bank:", error);
      // Handle error (e.g., display error message)
    }
  }
  // const handleMobileChange = (e) => {
  //   setMobile(e.target.value);
  // };

  // const handleAmountChange = (e) => {
  //   setAmount(e.target.value);
  // };

  return (
    <>
      <div className="leftContainer">
        <div>
          <div className="main-area" style={{ paddingTop: '60px' }} >
            <div className="loader" style={{ width: '100%', position: 'absolute', background: 'rgba(27, 29, 32, 0.51)', height: '100%', zIndex: 4, display: 'none' }}>
              <img src="https://vplay.bet/frontend/images/loader1.gif" style={{ width: '100%', top: '20%', position: 'absolute' }} />
            </div>
            <div className="px-4 py-3">
              <div className="head-battle" align="center">
                <div className="image">
                  <img src={money} alt="" style={{ width: '300px' }} />
                </div>
                <div className="font-weight-bold">Cash Won
                  <picture className="moneyIcon-container"><img src={rup} alt="" /></picture>
                  ₹{users.wallet}
                </div>
                <div className="kycPage">
                  <form method="post" autoComplete="off">
                    <input type="hidden" name="_token" defaultValue="Gd9E2uC5KGeNReVLl0CZdcU1i7n4T8wTX6JsQ84R" />
                    <p className="mt-2" style={{ color: 'rgb(149, 149, 149)', fontSize: '20px' }}>Enter details of
                      Friend</p><br />
                    <div id="sign_up_success" />
                    <div style={{ marginTop: '10px' }}>
                      <div className="kyc-doc-input">
                        <input type="tel" name="mobile" maxLength="10" id="mobile" required autoComplete="off" style={{ paddingTop: '20px' }} value={mobile} onChange={oninputchange} />
                        <div className="label" style={{ fontSize: '14px', paddingTop: '10px' }}>Friend's 10 digit
                          Registered Mobile No.</div>
                      </div> {validationErrors.mobile && <p>{validationErrors.mobile}</p>}<br />

                      <div className="kyc-doc-input">
                        <input type="number" id="amount" name="amount" min={0} max={-2360} required autoComplete="off" style={{ paddingTop: '20px' }} value={amount} onChange={oninputchange} />
                        <div className="label" style={{ fontSize: '14px', paddingTop: '10px' }}>Amount</div>
                      </div>
                      {validationErrors.amount && <p>{validationErrors.amount}</p>}
                    </div>
                    <div style={{ paddingBottom: '80px' }} />
                    <div className="row justify-content-center">
                      <button type="button" className="btn btn-primary my-2 w-100 mx-3 refer-button" id="redeemNow" onClick={handleAddBankSubmit}>Send Now</button>
                    </div>
                  </form>
                </div>
              </div>
              <hr />
            </div>
          </div>
          {/* <div className="divider-y" /> */}
          {/* <div className="rightContainer">
          <div className="rcBanner flex-center">
            <picture className="rcBanner-img-container">
              <img src="https://vplay.bet/frontend/images/vplaylogo1.png" alt="" />
            </picture>
            <div className="rcBanner-text">Vplay <span className="rcBanner-text-bold">Win Real Cash!</span></div>
            <div className="rcBanner-footer">For best experience, open&nbsp;<a href="#!" style={{color: 'rgb(44, 44, 44)', fontWeight: 500, textDecoration: 'none'}}>vplay.bet</a>&nbsp;on&nbsp;<img src="https://vplay.bet/frontend/images/global-chrome.png" alt="" />&nbsp;chrome mobile</div>
          </div>
        </div> */}
        </div>
      </div>
    </>

  );
}

export default Sendmoney;