




import React, { useEffect, useState } from "react";
import rup from '../images/global-rupeeIcon.png'
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import no_data from '../images/notransactionhistory.png';

import Offcanvas from 'react-bootstrap/Offcanvas';
const Transactionhistorypending = () => {
    const [users, setUsers] = useState([]);
    const [user, setSingelUsers] = useState([]);
    const [Usersleghted, setUsersleghted] = useState([]);

    const [showModal, setShowModal] = useState(false);


    function OffCanvasExample({ name, ...props }) {
        const [show, setShow] = useState(false);
        // alert(name);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);
        const token = localStorage.getItem("token_store_vplay");
        var data = name;
        // alert(name);
        var config = {
            method: 'post',
            url: 'https://api.vplay.bet/api/users/get-transaction-history-single',
            headers: {
                'x-access-token': token
            },
            id: name,
        };

        axios(config)
            .then(function (response) {

                // console.warn(response);
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                // console.warn(objectRes);
                // alert(objectRes.data)
                setSingelUsers(objectRes.data);
                console.log(user);
                config.setdata();
            })
            .catch(function (error) {
                console.log(error);
            });

        return (
            <div>
                <span onClick={handleShow} className="me-2" style={{ position: "absolute", left: "74px" }}>
                    <i class="fa fa-eye"></i>
                </span>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Withdrawal</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td><strong>Order ID</strong></td>
                                    <td>order_769445</td>
                                </tr>
                                <tr>
                                    <td><strong>Order ID</strong></td>
                                    <td>order_769445</td>
                                </tr>
                                <tr>
                                    <td><strong>Order ID</strong></td>
                                    <td>order_769445</td>
                                </tr>
                                <tr>
                                    <td><strong>Order ID</strong></td>
                                    <td><strong><button className="btn btn-success py-0">PAID</button></strong></td>
                                </tr>
                            </tbody>
                        </table>

                        <h5>Payment Details</h5>
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td>UPI</td>
                                    <td>2200</td>
                                </tr>
                            </tbody>
                        </table>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        );
    }

    useEffect(
        () => {
            loaduser();
            // alert('game')

        }, []
    );
    useEffect(
        () => {
            multiple_auth_chk();
        }, []
    );
    const multiple_auth_chk = async () => {


        var data = '';
        const ipaddress = localStorage.getItem("ipaddress");
        const token = localStorage.getItem("token_store_vplay");
        var config = {
            method: 'post',
            url: 'https://api.vplay.bet/api/users/multiple-auth-chk',
            headers: {
                'x-access-token': token
            },
            data: { ipaddress: ipaddress }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                const objectRes = JSON.parse(res);
                // console.warn(objectRes.data[0]['total']);
                var totlaipp = objectRes.data[0]['ip'];
                // alert(totlaipp);
                if (totlaipp != ipaddress) {
                    localStorage.setItem('token_store_vplay', ' ');
                    localStorage.setItem('userid', ' ');
                    window.location.href = '/login';
                }
                console.warn(totlaipp);
                // setUsers(objectRes);
            })
            .catch(function (error) {
                console.log(error);
            });
        if (!token) {
            window.location.href = '/login';
            // exist();
        }
    }
    const loaduser = async () => {
        const token = localStorage.getItem("token_store_vplay");

        var data = '';

        var config = {
            method: 'post',
            url: 'https://api.vplay.bet/api/users/get-transaction-history-pending',
            headers: {
                'x-access-token': token
            },
            data: data,
            // setdata: () => {
            //     sessionStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjczNDM1OTMxLCJleHAiOjE3MDQ5NzE5MzF9.lGDH-fUMWZpQzsOE7fD-xNQmirQM-Yvrlo3bFekhVBs')
            // }
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                // console.warn(res);
                const objectRes = JSON.parse(res);
                // console.log(objectRes.data);
                // alert(objectRes.data)
                setUsers(objectRes.data);
                var totalchk = objectRes.data;
                // console.log(users[2].id);
                var lengthed = totalchk.length;
                setUsersleghted(lengthed);
                // config.setdata();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ========viev======
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const checkstatus = (id) => {
        var config = {
            method: 'get',
            url: 'https://vplay.bet/Admin/public/check-status/phonepay/' + id,
            // headers: {
            //     'x-access-token': token
            // },
        };

        axios(config)
            .then(function (response) {
                const res = JSON.stringify(response.data);
                alert(res);
                window.location.href = '/Transactionhistorypending';
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <>
            <div className="leftContainer">
                <div>
                    <div className="main-area" style={{ paddingTop: '60px' }} />
                    <div className="container">
                        <a href="" className="text-light">
                            <div className="btn btn-primary w-100 my-2 ">
                                Transactionhistory Pending</div>
                        </a>
                    </div>
                </div>




                {Usersleghted > 0 ?
                    users && users.map((user, index) => (
                        <>
                            <div key={index} className="w-100 py-3 d-flex align-items-center list-item">
                                <div className="center-xy list-date mx-2">
                                    <div>{user.day}{user.month}</div><small>{user.paying_time}</small>
                                </div>
                                <div className="" id="card-1">

                                    {user.payment_type == 'withdraw' && user.add_or_withdraw == 'withdraw' ?
                                        <div className="card-content">
                                            {/* {['x'].map((placement, idx) => ( */}

                                            <OffCanvasExample key={user.id} placement="z" name={user.id} />

                                            {/* ))} */}
                                            {/* <a href="#close" className="close" onClick={handleShow}>x</a> */}
                                            {/* <a href="#close" variant="primary" onClick={handleShow} className="me-2 close" onClick={shoot(user.id)}>x</a> */}
                                            <div className="card__info" id="showhistory" >
                                            </div>
                                        </div>
                                        :
                                        <div className="card-content">
                                            <a href="#close" className="close"></a>
                                            <div className="card__info" id="showhistory" >
                                            </div>
                                        </div>

                                    }

                                </div>
                                <div className="mx-3 d-flex list-body">
                                    <div className="d-flex align-items-center" />
                                    <div className="d-flex flex-column font-8">
                                        {user.payment_type == 'reedem' ?
                                            <span>Reedem.</span>
                                            : user.payment_type == 'frend' && user.add_or_withdraw == 'withdraw' ?
                                                <span>Transfer By Friend. {user.UseridVplay}</span>
                                                :
                                                user.payment_type == 'frend' && user.add_or_withdraw == 'add' ?
                                                    <span>Added By Friend. {user.UseridVplay}</span>
                                                    :
                                                    user.payment_type == 'withdraw' && user.add_or_withdraw == 'withdraw' ?
                                                        <><span>Withdraw in {user.withdawtype}
                                                            {user.withdraw_status == 'pending' ?
                                                                <span style={{ color: "red" }}> {user.withdraw_status}</span>
                                                                :
                                                                <span style={{ color: "green" }}> {user.withdraw_status}</span>
                                                            }
                                                        </span></>
                                                        :
                                                        <span>Cash add.</span>

                                        }

                                        <div className="games-section-headline">Order ID:{user.order_id}</div>
                                        <button style={{ color: "white", backgroundColor: "green" }} onClick={() => checkstatus(user.order_id)}>Check Status</button>
                                    </div>
                                </div>
                                <div className="right-0 d-flex align-items-end pr-3 flex-column">
                                    <div className="d-flex float-right font-8">
                                        {user.add_or_withdraw == 'add' ?
                                            <div className="text-success">
                                                (+)
                                            </div>
                                            :
                                            <div className="text-danger">
                                                (-)
                                            </div>
                                        }
                                        <picture className="ml-1 mb-1"><img height="21px" width="21px" src={rup} alt="" /></picture><span className="pl-1">{user.amount}</span>
                                    </div>
                                    <div className="games-section-headline" style={{ fontSize: '0.6em' }}>Closing Balance: {user.closing_balance}</div>
                                </div>
                            </div>
                        </>
                    ))
                    :
                    <div className="nodata"><img width="70%" src={no_data} alt="" />
                        <p className="text-center" style={{ color: "#feb946" }}>Data is not available</p>
                    </div>

                }


                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Body> <div className="card-content">
                        <a href="#close" className="close" onClick={handleClose}>x</a>
                        <div className="card__info" id="showhistory">
                            <div>
                                <h3 style={{ paddingTop: '2%' }}>Withdraw</h3>
                                <hr />
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Order ID</strong></div>
                                    {/* <div className="col-md-6">order_{user.order_id}</div> */}
                                    <div className="col-md-6">order_769445</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Request Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Processed Time</strong></div>
                                    {/* <div className="col-md-6">{user.day}-{user.month}-{user.year} {user.paying_time}</div> */}
                                    <div className="col-md-6">02-04-2022 04:40 pm</div>
                                </div>
                                <div className="row align-items-center" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-6"><strong>Status</strong></div>
                                    <div className="col-md-6"><button className="btn btn-success py-0">PAID</button></div>
                                </div>
                                <br />
                                <h5>Payment Details</h5>
                                <div className="row pb-3" style={{ flexWrap: 'nowrap' }}>
                                    <div className="col-md-4">UPI</div>
                                    <div className="col-md-8">2200</div>
                                </div>
                            </div>
                        </div>
                    </div></Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div>
        </>

    );
}

export default Transactionhistorypending;