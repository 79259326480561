import React from "react";
import { Outlet, Link } from "react-router-dom";
const Rules = () => {
  return (
    <>
    <div className="container"></div>
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: '60px' }}>
          <div className="m-3">
            <h2>Vplay Classic Ludo Rules</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/vplay-bet">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Vplay Classic Ludo Rules</li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-12">
                <h4>Game Rules :-</h4>
                <ol className="rules-list">
                  <li>On Winning both players have to update their result in following manner.</li>
                  <ul>
                    <ul style={{ listStyleType: 'circle' }}>
                      <li>If you have won the balttle, select 'I won' option and upload winning
                        screenshot of the game.</li>
                      <li>If you have lost the battle, select 'I lost' option.</li>
                      <li>If your battle is not started and your opponent dosen't want to play, select
                        'cancel' option.</li>
                    </ul>
                  </ul>
                  <li>A player must have to record every game, and if any player is hacking or cheating in
                    a game, please contact support with video proof.</li>
                  <li>If your game is not started, and if you haven't played a single move yourself,
                    please show us a recording of the game as proof. The game will be canceled only if
                    you have recorded.</li>
                  <li>If you don't have any proof against player cheating and error in the game, then you
                    will be considered as lost for a particular battle.</li>
                  <li>If you haven't moved a single pawn or no pawn is open yet,i.e. all pawns are at
                    home, then your game will be cancelled.</li>
                  <li>If your opponent leaves match purposely in starting or initial game, and the
                    opponent dosen't have any valid proof of cancellation, you will be awarded a 50%
                    win.</li>
                </ol>
                <h4>Commission Rates :-</h4>
                <ol>
                  <li>Battle below 250₹, 5% <b>commission</b> will be charged on battle amount.</li>
                  <li>Battle between 250₹ to 500₹, <b>flat 25₹</b> commission will be charged.</li>
                  <li>Battle above 500₹, <b>commission</b> will be charged on battle amount.</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* </div> */}
    </>
  );
};
export default Rules;